import React from 'react'
import { graphql } from 'gatsby'

import { Bio } from '../components/layout/Bio'
import { Layout } from '../components/layout/Layout'
import { SEO } from '../components/seo'
import { ItemsList } from '../components/page/ItemsList'
import { BlogItem } from '../components/page/BlogItem'
import { H2 } from '../components/styled/H2'
import { PreviousNextPagination } from '../components/generic/PreviousNext'

function PostsInCategory({
  data: {
    allPosts: { edges: posts },
    category,
  },
  pageContext: { currentPage, numPages },
}) {
  return (
    <Layout>
      <SEO
        title={`${category.title} | Category`}
        currentPage={currentPage}
        numPages={numPages}
        image={`/og-images/categories/${category.slug.current}.png`}
      />
      <Bio />
      <ItemsList
        header={
          <>
            <H2>Category: {category.title}</H2>
            <p>{category.description}</p>
          </>
        }
        items={posts}
        component={BlogItem}
      />
      <PreviousNextPagination
        currentPage={currentPage}
        numPages={numPages}
        linkPrefix={`/category/${category.slug.current}/`}
      />
    </Layout>
  )
}

export default PostsInCategory

export const pageQuery = graphql`
  query CategoryPostsQuery($id: String!, $skip: Int!, $limit: Int!) {
    category: sanityCategory(id: { eq: $id }) {
      title
      description
      slug {
        current
      }
    }
    allPosts: allSanityPost(
      filter: { categories: { elemMatch: { id: { eq: $id } } } }
      sort: { fields: [publishedAt], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          ...BlogItem
        }
      }
    }
  }
`
